import React, { useState, useEffect, useCallback } from 'react';
import { fabric } from 'fabric';
import { FABRIC_JSON_PROPERTIES, CANVAS_DIMENSIONS } from '../constants/Constants';
import '../style/PreviewDialog.css';

const PreviewDialog = ({ isOpen, onClose, canvas, designType }) => {
        
    console.log(`********************************************* PreviewDialog 렌더링 [${designType}]`);

    const [previewImage, setPreviewImage] = useState('');

    const [imageLoaded, setImageLoaded] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        console.log(`PreviewDialog::useEffect(1)`);
        if (!isOpen) {
            setImageLoaded(false);
            setShowAnimation(false);
        }
        else {
            openPreview();
        
        }
    }, [isOpen]);

    // const handleClose = () => {
    //     setShowAnimation(false);
    //     // 애니메이션이 끝난 후 팝업 닫기
    //     setTimeout(() => {
    //         setIsOpen(false);
    //     }, 300); // transition 시간과 동일하게 설정
    // };


    const openPreview = useCallback(() => {
        console.log(`************************************************ PreviewDialog :: openPreview [${designType}]`);
        // if (!canvas) { return; }

        var newCanvas = new fabric.Canvas('newCanvasId');

        // CORS 관련 설정 추가
        fabric.Image.prototype.crossOrigin = 'anonymous';
        
        newCanvas.loadFromJSON(canvas.toJSON(FABRIC_JSON_PROPERTIES), function () {
            console.log(`openPreview newCanvas=${newCanvas.width}, ${newCanvas.height}`);

            // 캔버스의 테두리를 제거
            newCanvas.setWidth(CANVAS_DIMENSIONS.ORIGINAL_WIDTH - 4);
            newCanvas.setHeight(CANVAS_DIMENSIONS.ORIGINAL_HEIGHT - 4);
            console.log(`openPreview newCanvas=${newCanvas.width}, ${newCanvas.height}`);

            const group = newCanvas.getObjects().find(obj => obj.type === 'group' && obj.name === 'Overlay Group');
            if (designType === 'HARD') {
                group.getObjects().forEach(obj => {
                    if (obj.name === 'border') {
                    obj.set('opacity', 0);
                    }
                    else if (obj.name === 'insideArea') {
                    obj.set({
                        opacity: 1,
                        width: CANVAS_DIMENSIONS.ORIGINAL_WIDTH+4,
                        height: CANVAS_DIMENSIONS.ORIGINAL_HEIGHT+4,
                        top: 0,
                        left: 0,
                        originX: 'center',
                        originY: 'center',
                        rx: 0,
                        ry: 0,
                        fill: '#ffffff'
                    });
                    // obj.set('opacity', 1);
                    }
                    else if (obj.name === 'outsideArea') {
                    obj.set('opacity', 0);
                    }
                });
            }
            else if (designType === 'NEVER') {
                group.getObjects().forEach(obj => {
                    if (obj.name === 'outsideArea') {
                    obj.set({
                        width: CANVAS_DIMENSIONS.ORIGINAL_WIDTH+4,
                        height: CANVAS_DIMENSIONS.ORIGINAL_HEIGHT+4,
                        top: 0,
                        left: 0,
                        originX: 'center',
                        originY: 'center',
                        fill: '#ffffff'
                    });
                    }
                });
            }
            

            // 투명 TPU는 반투명으로 보여야 하기 때문에 미리보기에 필요한 객체만 가져와서 곱하기 해준다.
            if (designType === 'NEVER') {
                const objects = newCanvas.getObjects().filter(obj => !(obj.type === 'group' && obj.name === 'Overlay Group') && obj.name !== 'vGuideLine' );
                const newGroup = new fabric.Group([...objects], {
                    selectable: false,
                    evented: false,
                    opacity: 0.85,
                    globalCompositeOperation: 'multiply'
                });

                // 그룹화된 객체는 제거
                objects.forEach(obj => {
                    newCanvas.remove(obj);
                });

                newCanvas.add(newGroup);
                newCanvas.sendBackwards(newGroup);  // 오버레이보다는 아래 위치하도록
            }

            


            // 모든 객체가 로드된 후
            const dataUrl = newCanvas.toDataURL({
                format: 'png',
                quality: 1,
                multiplier: 1, // 해상도 유지
                width: newCanvas.width,
                height: newCanvas.height
            });
            setPreviewImage(dataUrl);
            // setIsOpen(true);

            
            // 팝업이 열린 직후 애니메이션 시작
            requestAnimationFrame(() => {
                setShowAnimation(true);
            });
        });

    }, [isOpen]);

    const handleImageLoad = () => {
        // 이미지가 로드된 후 약간의 지연을 주고 애니메이션 시작
        setTimeout(() => {
            setImageLoaded(true);
        }, 100);
    };

    if (!isOpen) {
        return (
            <button className="tmp-btn red" onClick={openPreview}>미리보기</button>
        );
    }

    return (
        <div className={`preview-dialog-overlay ${showAnimation ? 'show' : ''}`}>
            <div className={`preview-dialog-container`}>
                <div className="preview-dialog-header">
                    <h2>미리보기</h2>
                    <button onClick={onClose}>닫기</button>
                </div>
                <div className="preview-image-container">
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Preview"
                            className={`preview-image ${imageLoaded ? 'loaded' : ''}`}
                            onLoad={handleImageLoad}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PreviewDialog;