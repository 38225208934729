import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import '../style/TextControls.css';

const TextControls = forwardRef(({ selectedObject, canvas, historyControlsRef }, ref) => {

    console.log(`********************************************* TextControls 렌더링`);

    // 텍스트 관련 상태
    const [text, setText] = useState('');
    const [textStyle, setTextStyle] = useState({
        fontSize: 80,
        lineHeight: 1.0,
        tempLineHeight: 1.0,
        charSpacing: 0,
        tempCharSpacing: 0,
        isBold: false,
        isItalic: false,
    });

    // 폰트 관련 상태
    const [selectedFont, setSelectedFont] = useState('');
    const [isFontLoading, setIsFontLoading] = useState(false);
    const [fonts, setFonts] = useState([
        {
            name: 'Bagel Fat One Regular',
            path: '/assets/fonts/BagelFatOne-Regular.woff2',
            fontFamily: 'BagelFatOneRegular',
            isLoaded: false
        },
        {
            name: '태나다체',
            path: '/assets/fonts/Tenada.woff2',
            fontFamily: 'Tenada',
            isLoaded: false
        },
        // 추가 폰트들을 여기에 넣으세요
    ]);

    const updateTextStyle = (key, value) => {
        setTextStyle(prev => ({
            ...prev,
            [key]: value
        }));
    };

    // 외부에서 접근할 수 있는 메서드들을 정의
    useImperativeHandle(ref, () => ({
        setSelectedFont: (font) => {
            setSelectedFont(font);
        },
        updateTextPanel: (selectedObject) => {
            updateTextPanel(selectedObject);
        },
        updateTextStyle: (key, value) => {
            updateTextStyle(key, value);
        },
        fontDownload: async (font) => {
            return await fontDownload(font);
        },
    }));
    

    // 텍스트 스타일 변경 시 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(텍스트 스타일 변경 시 적용)`);
        if (selectedObject && selectedObject.type === 'text') {
            selectedObject.set({
                fontSize: textStyle.fontSize,
                lineHeight: textStyle.lineHeight,
                charSpacing: textStyle.charSpacing,
                fontWeight: textStyle.isBold ? 'bold' : 'normal',
                fontStyle: textStyle.isItalic ? 'italic' : 'normal',
            });
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textStyle]);

    const fontDownload = async (inputFont) => {
        const font = fonts.find(f => f.fontFamily === inputFont);
        if (!font || font.isLoaded) return;
    
        setIsFontLoading(true);
        
        try {
            const style = document.createElement('style');
            style.setAttribute('data-font', font.fontFamily);
            style.textContent = `
                @font-face {
                    font-family: '${font.fontFamily}';
                    src: url('${font.path}') format('woff2');
                    font-weight: normal;
                    font-style: normal;
                }
            `;
            await new Promise(resolve => {
                document.head.appendChild(style);
                // style 태그가 완전히 추가되었는지 확인
                setTimeout(resolve, 100);
            });

            // 2. FontFace 인스턴스 생성 및 로딩
            const fontFace = new FontFace(font.fontFamily, `url(${font.path})`);
            const loadedFont = await fontFace.load();

            // 3. 폰트 추가
            await new Promise(resolve => {
                document.fonts.add(loadedFont);
                resolve();
            });

            // 4. 폰트 준비 완료 대기
            await document.fonts.ready;

            console.log(`폰트 로딩 완료: ${inputFont}`);

            setIsFontLoading(false);
            setFonts(prevFonts =>
                prevFonts.map(f =>
                    f.fontFamily === font.fontFamily
                        ? { ...f, isLoaded: true }
                        : f
                )
            );

            // historyControlsRef.current.handleCanvasChange();
        } catch (error) {
            console.error('폰트 로딩 실패:', error);
            setIsFontLoading(false);
        }
    };

    // 폰트 다운로드 및 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(폰트 다운로드 및 적용)`);

        if (!selectedFont) return;

        const font = fonts.find(f => f.fontFamily === selectedFont);
        if (!font || font.isLoaded) return;

        fontDownload(selectedFont);

        return () => {
            const style = document.querySelector(`style[data-font="${font.fontFamily}"]`);
            if (style) {
                document.head.removeChild(style);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFont]);

    // 폰트 변경 시 적용
    useEffect(() => {
        console.log(`TextControls::useEffect(폰트 변경 시 적용)`);

        const font = fonts.find(f => f.fontFamily === selectedFont);
        if (!font) return;

        if (!isFontLoading && font.isLoaded && selectedObject && selectedObject.type === 'text') {
            selectedObject.set({
                fontFamily: selectedFont,
            });
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFontLoading, selectedFont]);


    // 텍스트 선택 시 input 내용 변경
    useEffect(() => {
        updateTextPanel(selectedObject);
    }, [selectedObject]);

    const updateTextPanel = (selectedObject) => {
        if (selectedObject && selectedObject.type === 'text') {
            console.log('updateTextPanel 1');
            setText(selectedObject.text);
            console.log('updateTextPanel 2');
            setTextStyle({
                fontSize: selectedObject.fontSize,
                lineHeight: selectedObject.lineHeight,
                charSpacing: selectedObject.charSpacing,
                tempLineHeight: selectedObject.lineHeight,
                tempCharSpacing: selectedObject.charSpacing,
                isBold: selectedObject.fontWeight === 'bold',
                isItalic: selectedObject.fontStyle === 'italic',
            });
            console.log('updateTextPanel 3');
            setSelectedFont(selectedObject.fontFamily);
        }
    };

    
    // 텍스트를 입력할때마다 실행
    const handleTextChange = (e) => {
        const activeObject = canvas?.getActiveObject();
        if (activeObject && activeObject.type === "text") {
            activeObject.set("text", e.target.value);
            setText(e.target.value);
            canvas.renderAll();
        }
    };


    // 텍스트 가운데 정렬
    const setTextAlign = (alignment) => {
        if (selectedObject && selectedObject.type === 'text') {
            selectedObject.set('textAlign', alignment);
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
    };

    // 텍스트 객체가 아닐 경우 렌더링하지 않음
    if (!selectedObject || selectedObject.type !== 'text' || selectedObject.lockMovementX) {
        return null;
    }

    return (
        <div style={{ backgroundColor: '#f5f5f5', border: '1px solid #ddd', padding: '10px' }}>
            <div>
                <textarea
                    className=""
                    rows="4"
                    value={text}
                    onChange={handleTextChange}
                    placeholder=""
                />
            </div>
            <label>
                폰트 크기:
                <input
                    type="range"
                    value={textStyle.fontSize}
                    onChange={(e) => updateTextStyle('fontSize', parseInt(e.target.value))}
                    min="10"
                    max="500"
                    step="5"
                />
                {textStyle.fontSize}px
            </label>

            <br/>
            <label>
                글자간격:
                <input
                    type="range"
                    value={textStyle.tempCharSpacing}
                    onChange={(e) => updateTextStyle('tempCharSpacing', parseInt(e.target.value))}
                    onMouseUp={() => updateTextStyle('charSpacing', textStyle.tempCharSpacing)}
                    onTouchEnd={() => updateTextStyle('charSpacing', textStyle.tempCharSpacing)}
                    step="0"
                    min="10"
                    max="1000"
                />
                {textStyle.charSpacing}
            </label>

            <br/>
            <label>
                행간격:
                <input
                    type="range"
                    value={textStyle.tempLineHeight}
                    onChange={(e) => updateTextStyle('tempLineHeight', parseFloat(e.target.value))}
                    onMouseUp={() => updateTextStyle('lineHeight', textStyle.tempLineHeight)}
                    onTouchEnd={() => updateTextStyle('lineHeight', textStyle.tempLineHeight)}
                    step="0.1"
                    min="0.1"
                    max="3.0"
                />
                {textStyle.lineHeight}x
            </label>

            <div className="text-style-controls">
                <label>
                    굵게:
                    <input
                        type="checkbox"
                        checked={textStyle.isBold}
                        onChange={(e) => updateTextStyle('isBold', e.target.checked)}
                    />
                </label>

                <label>
                    기울기:
                    <input
                        type="checkbox"
                        checked={textStyle.isItalic}
                        onChange={(e) => updateTextStyle('isItalic', e.target.checked)}
                    />
                </label>
            </div>

            <div className="font-controls">
                <label>
                    폰트:
                    <select
                        value={selectedFont}
                        onChange={(e) => setSelectedFont(e.target.value)}
                        disabled={isFontLoading}
                    >
                        <option value="">폰트 선택</option>
                        {fonts.map((font) => (
                            <option key={font.name} value={font.fontFamily}>
                                {font.name} {font.isLoaded ? '(로드됨)' : ''}
                            </option>
                        ))}
                    </select>
                </label>

                <div className="font-status">
                    {isFontLoading && (
                        <div className="loading-message">
                            새로운 폰트를 다운로드하고 있습니다. 잠시만 기다려주세요...
                        </div>
                    )}
                </div>
            </div>

            <div className="align-buttons">
                <button
                    className="align-button"
                    onClick={() => setTextAlign('left')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M120-120v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Z" />
                    </svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => setTextAlign('center')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M120-120v-80h720v80H120Zm160-160v-80h400v80H280ZM120-440v-80h720v80H120Zm160-160v-80h400v80H280ZM120-760v-80h720v80H120Z" />
                    </svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => setTextAlign('right')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M120-760v-80h720v80H120Zm240 160v-80h480v80H360ZM120-440v-80h720v80H120Zm240 160v-80h480v80H360ZM120-120v-80h720v80H120Z" />
                    </svg>
                </button>
            </div>
        </div>
    );
});

export default TextControls;