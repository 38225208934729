import React, { forwardRef, useState, useEffect } from 'react';
import { CANVAS_DIMENSIONS, CONTROL_VISIBILITY } from '../constants/Constants';


const OrderControls = forwardRef(({ canvas, selectedObject, historyControlsRef }, ref) => {
    
    console.log(`********************************************* OrderControls 렌더링`);

    const [canBringForward, setCanBringForward] = useState(false);
    const [canSendBackward, setCanSendBackward] = useState(false);

    // 버튼 활성화 상태 업데이트
    const updateButtonStates = () => {
        if (!canvas || !selectedObject) {
            setCanBringForward(false);
            setCanSendBackward(false);
            return;
        }

        const objectIndex = canvas.getObjects().indexOf(selectedObject);
        setCanBringForward(objectIndex < canvas.getObjects().length - 2);
        setCanSendBackward(objectIndex > 3);
    };

    // 선택된 객체나 캔버스가 변경될 때마다 상태 업데이트
    useEffect(() => {
        console.log(`OrderControls::useEffect(1)`);
        updateButtonStates();

        return () => {
          
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedObject, canvas]);


    useEffect(() => {
        console.log(`OrderControls::useEffect(2)`);
        // 키보드 이벤트 핸들러
        const handleKeyDown = (event) => {

            // input이나 textarea에서는 작동하지 않도록 처리
            if (event.target.tagName.match(/input|textarea/i)) {
                return;
            }

            // Delete 또는 Backspace 키 감지
            if (event.keyCode === 46 || event.keyCode === 8) {
                event.preventDefault();
                // console.log('Selected object changed:', selectedObject);
                deleteSelectedObject();
            }
        };

        // 이벤트 리스너 등록
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // 객체 삭제
    const deleteSelectedObject = () => {
        console.log('객체 삭제');

        if (canvas.getActiveObject()) {
            canvas.remove(canvas.getActiveObject());
            canvas.discardActiveObject();
            canvas.renderAll();
            // historyControlsRef.current.handleCanvasChange();
        }
    };

    // 앞으로 가져오기
    const bringForward = () => {
        if (canvas.getActiveObject()) {
            canvas.bringForward(canvas.getActiveObject());
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
            updateButtonStates(); // 순서 변경 후 상태 업데이트
        }
    };

    // 뒤로 보내기
    const sendBackward = () => {
        if (canvas.getActiveObject()) {
            canvas.sendBackwards(canvas.getActiveObject());
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
            updateButtonStates(); // 순서 변경 후 상태 업데이트
        }
    };

    /*
    // 맨 앞으로 가져오기
    const bringToFront = () => {
        if (canvas.getActiveObject()) {
            canvas.bringToFront(canvas.getActiveObject());
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
            updateButtonStates(); // 순서 변경 후 상태 업데이트
        }
    };

    // 맨 뒤로 보내기
    const sendToBack = () => {
        if (canvas.getActiveObject()) {
            canvas.sendToBack(canvas.getActiveObject());
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
            updateButtonStates(); // 순서 변경 후 상태 업데이트
        }
    };
    */

    const copyObject = () => {
        const activeObject = canvas.getActiveObject();

        if (activeObject) {
            
            activeObject.clone((clonedObj) => {

                clonedObj.set({
                  left: CANVAS_DIMENSIONS.CENTER_X,
                  top: CANVAS_DIMENSIONS.CENTER_Y,
                  selectable: true,
                  evented: true,
                });
                clonedObj.setControlsVisibility(CONTROL_VISIBILITY);

                canvas.add(clonedObj);
                canvas.setActiveObject(clonedObj);
                canvas.renderAll();
            });
        }
    };

    const alignObject = (alignment) => {
        const activeObject = canvas.getActiveObject();

        if (activeObject) {
            
            if (alignment === 'center') {
                activeObject.set({
                    left: CANVAS_DIMENSIONS.CENTER_X
                });
            }
            else if (alignment === 'vcenter') {
                activeObject.set({
                    top: CANVAS_DIMENSIONS.CENTER_Y
                });
            }
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
    };

    
    const transformObject = (transform) => {
        const activeObject = canvas.getActiveObject();

        if (activeObject) {
            
            if (transform === 'rotateRight') {
                activeObject.set({
                    angle: activeObject.angle + 45
                });
            }
            else if (transform === 'rotateLeft') {
                activeObject.set({
                    angle: activeObject.angle - 45
                });
            }
            else if (transform === 'flipX') {
                activeObject.set({
                    flipX: !activeObject.flipX
                });
            }
            else if (transform === 'flipY') {
                activeObject.set({
                    flipY: !activeObject.flipY
                });
            }
            else if (transform === 'lock') {
                activeObject.set({
                    lockMovement: true,
                    lockRotation: true,
                    lockScaling: true,
                    editable: false,
                    // styleEditable: false,  // 안먹힌다.
                    // lockColor: true  // 안먹힌다.
                });
            }
            else if (transform === 'unlock') {
                activeObject.set({
                    lockMovement: true,
                    lockRotation: true,
                    lockScaling: true,
                    editable: true,
                });
            }
            canvas.renderAll();
            historyControlsRef.current.handleCanvasChange();
        }
    };

    // 텍스트박스나 사각형인 경우에만 컨트롤 표시
    if (!selectedObject || (selectedObject.type !== 'text' && selectedObject.type !== 'textbox' && selectedObject.type !== 'rect' && selectedObject.type !== 'image') || selectedObject.name === 'BackgroundColor') {
        return null;
    }

    return (
        <div>
            <div className="align-buttons">
                <button
                    className="align-button"
                    onClick={deleteSelectedObject}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-48v-72h192v-48h192v48h192v72h-48v479.57Q720-186 698.85-165T648-144H312Zm336-552H312v480h336v-480ZM384-288h72v-336h-72v336Zm120 0h72v-336h-72v336ZM312-696v480-480Z" /></svg>
                </button>
                <button
                    className="align-button"
                    onClick={bringForward}
                    disabled={!selectedObject || !canBringForward || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M360-288q-29.7 0-50.85-21.15Q288-330.3 288-360v-384q0-29.7 21.15-50.85Q330.3-816 360-816h384q29.7 0 50.85 21.15Q816-773.7 816-744v384q0 29.7-21.15 50.85Q773.7-288 744-288H360Zm0-72h384v-384H360v384ZM216-216v72q-29.7 0-50.85-21.15Q144-186.3 144-216h72Zm-72-80v-72h72v72h-72Zm0-152v-72h72v72h-72Zm0-152v-72h72v72h-72Zm152 456v-72h72v72h-72Zm152 0v-72h72v72h-72Zm152 0v-72h72v72h-72Z" /></svg>
                </button>
                <button
                    className="align-button"
                    onClick={sendBackward}
                    disabled={!selectedObject || !canSendBackward || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-456h72v456h456v72H216Zm144-216v72q-29.7 0-50.85-21.15Q288-330.3 288-360h72Zm-72-80v-72h72v72h-72Zm0-152v-72h72v72h-72Zm72-152h-72q0-29.7 21.15-50.85Q330.3-816 360-816v72Zm80 456v-72h72v72h-72Zm0-456v-72h72v72h-72Zm152 0v-72h72v72h-72Zm0 456v-72h72v72h-72Zm152-456v-72q29.7 0 50.85 21.15Q816-773.7 816-744h-72Zm0 384h72q0 29.7-21.15 50.85Q773.7-288 744-288v-72Zm0-80v-72h72v72h-72Zm0-152v-72h72v72h-72Z" /></svg>
                </button>
                {/* <button
                    onClick={bringToFront}
                    disabled={!selectedObject || !canBringForward}
                >
                    맨 앞으로
                </button>
                <button
                    onClick={sendToBack}
                    disabled={!selectedObject || !canSendBackward}
                >
                    맨 뒤로
                </button> */}
                <button
                    className="align-button"
                    onClick={copyObject}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M360-240q-29.7 0-50.85-21.15Q288-282.3 288-312v-480q0-29.7 21.15-50.85Q330.3-864 360-864h384q29.7 0 50.85 21.15Q816-821.7 816-792v480q0 29.7-21.15 50.85Q773.7-240 744-240H360Zm0-72h384v-480H360v480ZM216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-552h72v552h456v72H216Zm144-216v-480 480Z"/></svg>
                </button>

                <button
                    className="align-button"
                    onClick={() => transformObject('lock')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M263.72-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.23-135.84 56.22-56.16 136-56.16Q560-912 616-855.84q56 56.16 56 135.84v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Zm.28-72h432v-384H264v384Zm216.21-120Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Zm-96 456v-384 384Z" /></svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => transformObject('unlock')}
                    disabled={!selectedObject || !selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M264-624h336v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85h-72q0-80 56.23-136 56.22-56 136-56Q560-912 616-855.84q56 56.16 56 135.84v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624Zm0 456h432v-384H264v384Zm216.21-120Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM264-168v-384 384Z" /></svg>
                </button>
            </div>

            <div className="align-buttons">
                <button
                    className="align-button"
                    onClick={() => transformObject('rotateLeft')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M440-80q-50-5-96-24.5T256-156l56-58q29 21 61.5 34t66.5 18v82Zm80 0v-82q104-15 172-93.5T760-438q0-117-81.5-198.5T480-718h-8l64 64-56 56-160-160 160-160 56 58-62 62h6q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-438q0 137-91 238.5T520-80ZM198-214q-32-42-51.5-88T122-398h82q5 34 18 66.5t34 61.5l-58 56Zm-76-264q6-51 25-98t51-86l58 56q-21 29-34 61.5T204-478h-82Z"/></svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => transformObject('rotateRight')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M522-80v-82q34-5 66.5-18t61.5-34l56 58q-42 32-88 51.5T522-80Zm-80 0Q304-98 213-199.5T122-438q0-75 28.5-140.5t77-114q48.5-48.5 114-77T482-798h6l-62-62 56-58 160 160-160 160-56-56 64-64h-8q-117 0-198.5 81.5T202-438q0 104 68 182.5T442-162v82Zm322-134-58-56q21-29 34-61.5t18-66.5h82q-5 50-24.5 96T764-214Zm76-264h-82q-5-34-18-66.5T706-606l58-56q32 39 51 86t25 98Z"/></svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => transformObject('flipX')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M385-144H216q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h169v72H216v528h169v72Zm59 96v-864h72v864h-72Zm150-96v-72h72v72h-72Zm0-600v-72h72v72h-72Zm150 600v-72h72q0 30-21.15 51T744-144Zm0-150v-72h72v72h-72Zm0-150v-72h72v72h-72Zm0-150v-72h72v72h-72Zm0-150v-72q29.7 0 50.85 21.15Q816-773.7 816-744h-72Z"/></svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => transformObject('flipY')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368">
                        <g transform="rotate(-90 480 -480)">
                            <path d="M385-144H216q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h169v72H216v528h169v72Zm59 96v-864h72v864h-72Zm150-96v-72h72v72h-72Zm0-600v-72h72v72h-72Zm150 600v-72h72q0 30-21.15 51T744-144Zm0-150v-72h72v72h-72Zm0-150v-72h72v72h-72Zm0-150v-72h72v72h-72Zm0-150v-72q29.7 0 50.85 21.15Q816-773.7 816-744h-72Z" />
                        </g>
                    </svg>
                </button>


                {/* <button
                    className="align-button"
                    onClick={() => setAlignObject('left')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M120-120v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Z" />
                    </svg>
                </button> */}
                <button
                    className="align-button"
                    onClick={() => alignObject('center')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M440-80v-800h80v800h-80Zm160-200v-400h120v400H600Zm-360 0v-400h120v400H240Z"/></svg>
                </button>
                <button
                    className="align-button"
                    onClick={() => alignObject('vcenter')}
                    disabled={!selectedObject || selectedObject.lockMovement}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M80-440v-80h800v80H80Zm200-120v-120h400v120H280Zm0 280v-120h400v120H280Z"/></svg>
                </button>
                {/* <button
                    className="align-button"
                    onClick={() => setAlignObject('right')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M120-760v-80h720v80H120Zm240 160v-80h480v80H360ZM120-440v-80h720v80H120Zm240 160v-80h480v80H360ZM120-120v-80h720v80H120Z" />
                    </svg>
                </button> */}
            </div>

        </div>
    );
});

export default OrderControls;