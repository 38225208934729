import React, { useLayoutEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import useUserCookie from './hooks/useUserCookie';
import CanvasContainer from "./components/CanvasContainer";
import { MARKET_ID, FABRIC_JSON_PROPERTIES, API_INFO } from "./constants/Constants";
import { PRODUCT_SETTINGS } from "./constants/ProductSettings";
import "./style/App.css";

function App() {
    console.log('************************************* App 렌더링');

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;



    const selectedSettingsRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    
    // 인증 관련 정보
    const marketIdRef = useRef(null);
    const userIdRef = useRef(null);

    // 상품 관련 정보
    const designTypeRef = useRef(null);
    const deviceCodeRef = useRef(null);
    const designCodeRef = useRef(null);
    const customNoRef = useRef(null);


    const { getUserId, getTempIdYn, setUserIdCookie, deleteUserIdCookie } = useUserCookie();


    // useRef를 사용하여 refs 배열 생성
    const canvasRefs = useRef([]); // 모든 캔버스 컨테이너
    const containerRefs = useRef([]); // 모든 컨테이너
    // const [selectedCanvas, setSelectedCanvas] = useState(null);
    // const canvasContainerRef = useRef(null); // 캔버스 컨테이너
    // const canvasContainerRef2 = useRef(null); // 캔버스 컨테이너



   
    useLayoutEffect(() => {
        console.log(`파라미터 처리`);

        // URL 파라미터 우선. userId가 있고 urlTempIdYn=N이면 회원 로그인
        // 회원ID를 쿠키에 저장하지 않음
        // 회원 로그인이어도 임시ID 쿠키는 그냥 둔다.
                
        const initializeSettings = async () => {
            try {
                const domain = window.location.hostname;
                const params = new URLSearchParams(window.location.search);

                //-----------------------------
                // 인증 처리
                //-----------------------------

                marketIdRef.current = MARKET_ID[domain];
                console.log(`marketIdReg.current=${marketIdRef.current}`);

                // URL 파라미터에서 userId 확인
                const urlUserId = params.get("userId");
                const urlTempIdYn = params.get("tempIdYn");
                console.log(`url param    : userId=${urlUserId}, tempIdYn=${urlTempIdYn}`);

                // 쿠키에 저장된 임시ID 확인
                const cookieUserId = getUserId();
                const cookieTempIdYn = getTempIdYn();
                console.log(`cookie param : userId=${cookieUserId}, tempIdYn=${cookieTempIdYn}`);

                let paramUserId;
                let paramTempIdYn;

                // 로그인 회원
                if (urlTempIdYn === "N" && urlUserId && urlUserId.indexOf("TEMP_") === -1) {
                    paramUserId = urlUserId;
                    paramTempIdYn = "N";
                } else if (cookieUserId) {
                    paramUserId = cookieUserId;
                    paramTempIdYn = cookieTempIdYn;
                } else {
                    deleteUserIdCookie();
                    paramUserId = null;
                    paramTempIdYn = "Y";
                }

                // FormData 생성
                const formData = {
                    marketId: marketIdRef.current,
                    userId: paramUserId,
                    tempIdYn: paramTempIdYn,
                };

                console.log("formData:", formData);

                // API 호출
                fetch(SERVER_DOMAIN + API_INFO.ENDPOINTS.USER_AUTH_VERIFY, {
                    method: "POST",
                    headers: API_INFO.JSON_HEADERS,
                    body: JSON.stringify(formData),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        console.log("Success:", data.resObj);
                        userIdRef.current = data.resObj.userId;
                        if (data.resObj.tempIdYn === "Y") {
                            setUserIdCookie(data.resObj.userId, data.resObj.tempIdYn);
                        }
                        console.log("Success =======================================");
                    })
                    .catch((error) => {
                        console.error("Upload error:", error);
                        alert('인증 에러');
                    });

                //-----------------------------
                // 초기화 파라미터 처리
                //-----------------------------

                customNoRef.current = params.get("customNo");
                designTypeRef.current = params.get("designType");
                deviceCodeRef.current = params.get("deviceCode");
                designCodeRef.current = params.get("designCode");
                // console.log(`product params=>${customNoRef.current}, ${designTypeRef.current}, ${deviceCodeRef.current}`);
                // console.log('selectedSettingsRef.current=',selectedSettingsRef.current);

                selectedSettingsRef.current = PRODUCT_SETTINGS[designTypeRef.current];
                canvasRefs.current = canvasRefs.current.slice(0, selectedSettingsRef.current.length);
                containerRefs.current = containerRefs.current.slice(0, selectedSettingsRef.current.length);
            
                setTimeout(function() {
                    changeCanvas(0);
                }, 500);

                // setSelectedCanvas(canvasRefs.current[0]);

                setIsLoading(false); // 모든 설정이 완료되면 로딩 상태를 false로 변경
                console.log(`로딩 완료 ${isLoading}`);
            } catch (error) {
                console.error("초기화 에러:", error);
                alert('초기화 중 에러가 발생했습니다.');
                setIsLoading(false);
            }
        };

        initializeSettings();
        
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 완료 - 서버에 편집 정보 저장 (모든 내용)
     */
    const saveContentsToServer = async () => {
      
        // FormData 생성
        const mainFormData = new FormData();
        mainFormData.append("marketId", marketIdRef.current);
        mainFormData.append("userId", userIdRef.current);
        mainFormData.append("designType", designTypeRef.current);
        mainFormData.append("deviceCode", deviceCodeRef.current);
        mainFormData.append("designCode", designCodeRef.current);

        // API 호출
        fetch(SERVER_DOMAIN + API_INFO.ENDPOINTS.EDITED_IMAGE_UPLOAD, {
            method: "POST",
            // headers: API_INFO.JSON_HEADERS,
            body: mainFormData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Success:", data.resObj.customNo);
                // customNoRef.current = data.resObj.customNo;

                canvasRefs.current.forEach(async (container, index) => {
                    const canvas = container.getCanvas();
                    console.log(`canvas=${canvas}`);
                    await saveEditedImageAndJson(canvas, data.resObj.customNo, index+1);
                });

                return data;
            })
            .then((data) => {
                console.log("Success:", data.resObj.customNo);
                customNoRef.current = data.resObj.customNo;

                // // 부모창으로 데이터 전송
                // window.parent.postMessage(
                //     {
                //         thumbUrl: `${IMG_SERVER_DOMAIN}/${data.resObj.thumbImgUrl}`,
                //         customNo: data.resObj.customNo,
                //     },
                //     "*"
                // ); // '*'를 실제 부모 도메인으로 변경할 것!
                // console.log(`부모창으로 데이터 전송 !!! + ${customNoRef.current}`);
            })
            .catch((error) => {
                console.error("Upload error:", error);
            });

    };

    /**
     * 서버에 편집 정보 저장 (캔버스단위)
     */
    const saveEditedImageAndJson = async (canvas, customNo, seq) => {

        // 현재 캔버스의 상태 저장
        const currentZoom = canvas.getZoom();
        const currentWidth = canvas.getWidth();
        const currentHeight = canvas.getHeight();

        const PRINT_SCALE = 1;

        // 원본 스케일로 변경
        const scaleFactor = PRINT_SCALE / currentZoom;
        canvas.setZoom(PRINT_SCALE);
        canvas.setWidth(currentWidth * scaleFactor);
        canvas.setHeight(currentHeight * scaleFactor);
        canvas.renderAll();

        try {
            //--------------------
            // 썸네일용
            //--------------------
            const thumbDataURL = canvas.toDataURL({
                format: "png",
                quality: 1, // 1은 최대 품질
            });
            const thumbBlobData = await (await fetch(thumbDataURL)).blob();

            //--------------------
            // 프린팅용
            //--------------------
            const printingBlobData = await getPrintingBlob(canvas);

            //--------------------
            // JSON
            //--------------------
            const objects = canvas
                .getObjects()
                .filter((obj) => !obj.isStatic)
                .map((obj) => {
                    if (obj.type === "group") {
                        return processGroup(obj);
                    }
                    return obj.toObject(FABRIC_JSON_PROPERTIES);
                });

            const json = {
                version: canvas.version,
                objects: objects,
            };

            const jsonStr = JSON.stringify(json);
            const blob = new Blob([jsonStr], { type: "application/json" });
            const jsonFile = new File([blob], "edited_image.json", { type: "application/json" });

            // FormData 생성
            const formData = new FormData();
            formData.append("marketId", marketIdRef.current);
            formData.append("userId", userIdRef.current);
            formData.append("customNo", customNo);
            formData.append("seq", seq);
            formData.append("area", null);
            formData.append("thumbFile", thumbBlobData, "canvas-thumb-image.png");
            formData.append("printingFile", printingBlobData, "canvas-printing-image.png");
            formData.append("jsonFile", jsonFile);

            // API 호출
            fetch(SERVER_DOMAIN + API_INFO.ENDPOINTS.EDITED_IMAGE_UPLOAD_DATA, {
                method: "POST",
                headers: API_INFO.MULTIPART_HEADERS,
                body: formData,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Sub Success:", data.resObj.customNo);
                    // customNoRef.current = data.resObj.customNo;

                    if (seq === 1) {
                        console.log(`thumbImgUrl ==> ${IMG_SERVER_DOMAIN}/${data.resObj.thumbImgUrl}`);

                        // 부모창으로 데이터 전송
                        window.parent.postMessage(
                            {
                                thumbUrl: `${IMG_SERVER_DOMAIN}/${data.resObj.thumbImgUrl}`,
                                customNo: data.resObj.customNo,
                            },
                            "*"
                        ); // '*'를 실제 부모 도메인으로 변경할 것!
                        console.log(`부모창으로 데이터 전송 !!! + ${customNoRef.current}`);
                    }
                })
                .catch((error) => {
                    console.error("Upload error:", error);
                });
        } catch (error) {
            console.error("Upload error:", error);
            alert("업로드 중 오류가 발생했습니다.");
        } finally {
            // 줄여진 스케일로 변경
            canvas.setZoom(currentZoom);
            canvas.setWidth(currentWidth);
            canvas.setHeight(currentHeight);
            canvas.renderAll();
        }
    };

    /**
     * PNG 저장 - 서버에 이미지 저장
     */
    const getPrintingBlob = (canvas) => {
        return new Promise((resolve, reject) => {

            // 현재 캔버스의 상태 저장
            const currentZoom = canvas.getZoom();
            const currentWidth = canvas.getWidth();
            const currentHeight = canvas.getHeight();

            // 캔버스 복사본 생성
            const tempCanvas = new fabric.Canvas(document.createElement("canvas"), {
                width: currentWidth,
                height: currentHeight,
            });

            const objects = canvas
                .getObjects()
                .filter((obj) => !obj.isStatic)
                .map((obj) => {
                    if (obj.type === "group") {
                        return processGroup(obj);
                    }
                    return obj.toObject(FABRIC_JSON_PROPERTIES);
                });

            const json = {
                version: canvas.version,
                objects: objects,
            };

            const jsonStr = JSON.stringify(json);
            console.log(`jsonStr=${jsonStr}`);

            tempCanvas.loadFromJSON(jsonStr, function () {
                const PRINT_SCALE = 1;

                // 원본 스케일로 변경
                const scaleFactor = PRINT_SCALE / currentZoom;
                tempCanvas.setZoom(PRINT_SCALE);
                tempCanvas.setWidth(currentWidth * scaleFactor);
                tempCanvas.setHeight(currentHeight * scaleFactor);
                tempCanvas.renderAll();

                try {
                    // 데이터 URL 생성
                    const dataURL = tempCanvas.toDataURL({
                        format: "png",
                        quality: 1,
                    });

                    // Base64 데이터를 Blob으로 변환
                    const blobData = base64ToBlob(dataURL);
                    resolve(blobData);
                } catch (error) {
                    console.error("blobData error:", error);
                    reject(error);
                } finally {
                    // 임시 캔버스 제거
                    tempCanvas.dispose();
                }
            });
        });
    };

    const base64ToBlob = (base64Data) => {
        const byteString = atob(base64Data.split(",")[1]);
        return new Blob([new Uint8Array(byteString.split("").map((char) => char.charCodeAt(0)))], {
            type: "image/png",
        });
    };

    const processGroup = (group) => {
        return {
            type: group.type,
            left: group.left,
            top: group.top,
            width: group.width,
            height: group.height,
            scaleX: group.scaleX,
            scaleY: group.scaleY,
            angle: group.angle,
            selectable: group.selectable,
            evented: group.evented,
            hasControls: group.hasControls,
            objects: group.getObjects().map((obj) => {
                // 중첩 그룹 처리
                if (obj.type === "group") {
                    return processGroup(obj);
                }
                return obj.toObject(FABRIC_JSON_PROPERTIES.filter((prop) => prop !== "group"));
            }),
        };
    };

    /**
     * 소스 불러오기 - 서버
     * @param {*} e
     * @returns
     */
    const loadContents = async () => {
        // const canvas = canvasContainerRef.current;

        fetch(SERVER_DOMAIN + API_INFO.ENDPOINTS.EDITED_IMAGE_LOAD + customNoRef.current, {
            method: "GET",
            headers: API_INFO.JSON_HEADERS,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                console.log("editedImage json:", data.resObj.editedImage);
                // console.log("canvasRefs.current[0]:", canvasRefs.current[0].getCanvas());
                // console.log("canvasRefs.current[1]:", canvasRefs.current[1].getCanvas());

                const editedImage = data.resObj.editedImage;

                editedImage.editedImageSubs.forEach(async (editedImageSub, index) => {
                    const canvas = canvasRefs.current[index].getCanvas();
                    console.log(`editedImageSub.seq=${editedImageSub.seq}`);
                    fabric.util.enlivenObjects(JSON.parse(editedImageSub.json).objects, function (objects) {
                        objects.forEach((obj) => {
                            canvas.add(obj);
                        });
                        canvas.requestRenderAll();
                        canvasRefs.current[index].updateOverlayGroup();
                    });
                    
                });

                return data;
            })
            .catch((error) => {
                alert("저장 데이터를 가져오는 중 에러가 발생하였습니다.");
            });
    };

    

    const changeCanvas = (no) => {
        console.log('changeCanvas 초기화 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');

        containerRefs.current.forEach((container, index) => {
            if (no === index) {
                return;
            }
            containerRefs.current[index].style.display = 'none';
        });

        containerRefs.current[no].style.display = 'block';
        

        // setSelectedCanvas(canvasRefs.current[no]);
    };

    // if (!selectedCanvas) {
    //     return;
    // }

    return (
        <div className="App">
            {isLoading && <div style={{ height: "200px" }}>로딩 중...</div>}
            {!isLoading && (
                <>
                    <div className="top-menu">
                        <button onClick={saveContentsToServer} className="tmp-btn red">
                            완료
                        </button>
                        <button onClick={loadContents} className="tmp-btn">불러오기-서버(임시)</button>
                        
                        {Object.keys(selectedSettingsRef.current).map((area, index) => (
                            <button key={area} onClick={() => changeCanvas(index)} className="tmp-btn">
                                {selectedSettingsRef.current[area].areaName}
                            </button>
                        ))}

                    </div>

                    {Object.keys(PRODUCT_SETTINGS[designTypeRef.current]).map((area, index) => (
                        <div key={area} ref={(el) => (containerRefs.current[index] = el)}>
                            <CanvasContainer
                                ref={(el) => (canvasRefs.current[index] = el)}
                                canvasId={`canvas${index + 1}`}
                                marketId={marketIdRef.current}
                                userId={userIdRef.current}
                                designType={designTypeRef.current}
                                designCode={designCodeRef.current}
                                deviceCode={deviceCodeRef.current}
                                area={area}
                                initSettings={selectedSettingsRef.current}
                            />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default App;
