import React, { useEffect, useLayoutEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { FABRIC_JSON_PROPERTIES } from '../constants/Constants';

const HistoryControls = forwardRef(({ canvas, setHisIndex, updateOverlayGroup }, ref) => {
    
    console.log(`********************************************* HistoryControls 렌더링`);

    const [historyIndex, setHistoryIndex] = useState(0);
    const canvasHistoryRef = useRef([]);
    const isRedoUndoRef = useRef(false);

    // 외부에서 접근할 수 있는 메서드들을 정의
    useImperativeHandle(ref, () => ({
        setIsRedoUndo: (value) => {
            isRedoUndoRef.current = value;
        },
        getIsRedoUndo: () => isRedoUndoRef.current,
        handleCanvasChange: () => handleCanvasChange(),
        reset: () => reset(),
    }));

    useEffect(() => {
        console.log(`HistoryControls::useEffect(1)`);

        if (!canvas) return;

        // 캔버스 변경 이벤트 핸들러 등록
        canvas.on('object:added', handleCanvasChange);
        canvas.on('object:modified', handleCanvasChange);
        canvas.on('object:removed', handleCanvasChange);

        return () => {
            // Clean up event listeners
            canvas.off('object:added', handleCanvasChange);
            canvas.off('object:modified', handleCanvasChange);
            canvas.off('object:removed', handleCanvasChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas]);

    useLayoutEffect(() => {
        console.log(`HistoryControls::useLayoutEffect(1)`);
        // isRedoUndoRef.current = true;
        setHisIndex(historyIndex);
        // isRedoUndoRef.current = false;
    }, [historyIndex, setHisIndex]);

    const handleCanvasChange = () => {
        if (isRedoUndoRef.current) {
            return;
        }

        const canvasJson = canvas.toJSON(FABRIC_JSON_PROPERTIES);

        setHistoryIndex((prevHistoryIndex) => {
            // 이전으로 1번이상 진행 후 객체 변경 시 뒤쪽 히스토리 제거
            if (prevHistoryIndex < canvasHistoryRef.current.length - 1) {
                canvasHistoryRef.current = canvasHistoryRef.current.slice(0, prevHistoryIndex + 1);
            }

            canvasHistoryRef.current.push(canvasJson);

            const lastIndex = canvasHistoryRef.current.length - 1;
            
            return lastIndex;
        });
        
        updateOverlayGroup();
    };

    // 히스토리 초기화
    const reset = () => {
        setHistoryIndex((prevHistoryIndex) => {
            if (prevHistoryIndex > 0) {
                const newIndex = 0;
                const canvasJson = canvasHistoryRef.current[newIndex];
                isRedoUndoRef.current = true;
                canvas.loadFromJSON(canvasJson, () => {
                    canvas.renderAll();
                    isRedoUndoRef.current = false;
                    updateOverlayGroup(canvas);
                });
                return newIndex;
            }
            return prevHistoryIndex;
        });
    };

    // 이전으로
    const undo = () => {
        setHistoryIndex((prevHistoryIndex) => {
            if (prevHistoryIndex > 0) {
                const newIndex = prevHistoryIndex - 1;
                const canvasJson = canvasHistoryRef.current[newIndex];
                isRedoUndoRef.current = true;
                canvas.loadFromJSON(canvasJson, () => {
                    canvas.renderAll();
                    isRedoUndoRef.current = false;
                    updateOverlayGroup(canvas);
                });
                return newIndex;
            }
            return prevHistoryIndex;
        });
    };

    // 다시 실행
    const redo = () => {
        setHistoryIndex((prevHistoryIndex) => {
            if (prevHistoryIndex < canvasHistoryRef.current.length - 1) {
                const newIndex = prevHistoryIndex + 1;
                const canvasJson = canvasHistoryRef.current[newIndex];
                isRedoUndoRef.current = true;
                canvas.loadFromJSON(canvasJson, () => {
                    canvas.renderAll();
                    isRedoUndoRef.current = false;
                    updateOverlayGroup(canvas);
                });
                return newIndex;
            }
            return prevHistoryIndex;
        });
    };

    return (
        <div className="align-buttons">
            <button
                className="align-button"
                onClick={reset}
                disabled={historyIndex <= 0}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M444-144q-107-14-179.5-94.5T192-430q0-61 23-113.5t63-91.5l51 51q-30 29-47.5 69T264-430q0 81 51.5 140T444-217v73Zm72 0v-73q77-13 128.5-72.5T696-430q0-90-63-153t-153-63h-7l46 46-51 50-132-132 132-132 51 51-45 45h6q120 0 204 84t84 204q0 111-72.5 192T516-144Z"/></svg>
            </button>
            <button
                className="align-button"
                onClick={undo}
                disabled={historyIndex <= 0}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z"/></svg>
            </button>
            <button
                className="align-button"
                onClick={redo}
                disabled={historyIndex >= canvasHistoryRef.current.length - 1}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M396-200q-97 0-166.5-63T160-420q0-94 69.5-157T396-640h252L544-744l56-56 200 200-200 200-56-56 104-104H396q-63 0-109.5 40T240-420q0 60 46.5 100T396-280h284v80H396Z"/></svg>
            </button>
            <span>History Index: {historyIndex}</span>
        </div>
    );
});

export default HistoryControls;