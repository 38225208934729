import { useCallback } from 'react';
import Cookies from 'js-cookie';

const useUserCookie = () => {

    // 쿠키 저장하기
    const setUserIdCookie = useCallback((newUserId, newTempIdYn, expirationDays = 31) => {
        console.log(`useUserCookie::setUserIdCookie(${newUserId},${newTempIdYn})`);

        Cookies.set('userId', newUserId, { expires: expirationDays, path: '/' });
        Cookies.set('tempIdYn', newTempIdYn, { expires: expirationDays, path: '/' });
    }, []);

    // 쿠키 삭제하기
    const deleteUserIdCookie = useCallback(() => {
        Cookies.remove('userId', { path: '/' });
        Cookies.remove('tempIdYn', { path: '/' });
    }, []);

    const getUserId = () => {
        const savedUserId = Cookies.get('userId');

        console.log(`useUserCookie::getUserId() => ${savedUserId}`);
        
        if (!savedUserId) {
            return;
        }

        return savedUserId;
    };

    const getTempIdYn = () => {
        const savedTempIdYn = Cookies.get('tempIdYn');

        console.log(`useUserCookie::getTempIdYn() => ${savedTempIdYn}`);

        if (!savedTempIdYn) {
            return;
        }

        return savedTempIdYn;
    };

    return {
        getUserId,
        getTempIdYn,
        setUserIdCookie,
        deleteUserIdCookie
    };
};

export default useUserCookie;