import React, { useEffect, useState, memo, useCallback } from "react";
import { throttle } from "lodash";

const SizeInfoTooltip = memo(({ canvas, selectedObject }) => {

    console.log(`********************************************* SizeInfoTooltip 렌더링`);

    // State for tooltip information
    const [sizeInfoTooltip, setSizeInfoTooltip] = useState({
        isModify: false,
        size: {
            angle: 0,
            width: 0,
            height: 0,
        },
        style: {
            left: 0,
            top: 0,
            display: "none",
        },
    });

    // Memoize updateTooltip to prevent recreation on each render
    const updateTooltip = useCallback(
        throttle((target) => {
            if (!canvas || !target) return;

            const angle = Math.round(target.angle);
            const width = Math.round(target.getScaledWidth());
            const height = Math.round(target.getScaledHeight());

            const zoom = canvas.getZoom();
            const objCenter = target.getCenterPoint();
            const canvasOffset = canvas.getElement().getBoundingClientRect();

            const left = `${canvasOffset.left + (objCenter.x - 150) * zoom}px`;
            const top = `${canvasOffset.top + objCenter.y * zoom + (height / 2) * zoom + 20}px`;

            setSizeInfoTooltip({
                isModify: true,
                size: { angle, width, height },
                style: { left, top, display: "block" },
            });
        }, 100),
        [canvas] // Include canvas in dependencies since it's used inside
    );

    // Setup canvas event listeners
    useEffect(() => {
        if (!canvas) return;

        const handleScaling = (e) => updateTooltip(e.target);
        const handleRotating = (e) => updateTooltip(e.target);

        canvas.on("object:scaling", handleScaling);
        canvas.on("object:rotating", handleRotating);

        return () => {
            canvas.off("object:scaling", handleScaling);
            canvas.off("object:rotating", handleRotating);
        };
    }, [canvas, updateTooltip]);

    // Handle object modification
    useEffect(() => {
        if (!canvas) return;

        const handleModified = () => {
            setSizeInfoTooltip((prevState) => ({
                ...prevState,
                style: { ...prevState.style, display: "none" },
            }));
        };

        canvas.on("object:modified", handleModified);

        return () => {
            canvas.off("object:modified", handleModified);
        };
    }, [canvas]);

    if (!selectedObject) return null;

    const { top, left, display } = sizeInfoTooltip.style;
    const { angle, width, height } = sizeInfoTooltip.size;

    return (
        <div id="tooltip" className="tooltip" style={{ width: "150px", top, left, display }}>
            {`${angle}°, ${width} x ${height}`}
        </div>
    );
});

// Add display name for debugging purposes
SizeInfoTooltip.displayName = "SizeInfoTooltip";

export default SizeInfoTooltip;
