export const PRODUCT_SETTINGS = {
    NEVER: {
        GENERAL: {
            areaName: "1번",
            phoneBaseImage: "/static-img/mockup/NEVER/NEVER-{DEVICE_CODE}-base.png",
            overlayImage: "/static-img/mockup/NEVER/NEVER-{DEVICE_CODE}-overlay.png",
        },
        GENERAL2: {
            areaName: "2번",
            phoneBaseImage: "/static-img/mockup/NEVER/NEVER-{DEVICE_CODE}-base.png",
            overlayImage: "/static-img/mockup/NEVER/NEVER-{DEVICE_CODE}-overlay.png",
        },
    },
    HARD: {
        GENERAL: {
            areaName: "1",
            phoneBaseImage: "/static-img/mockup/HARD/HARD-{DEVICE_CODE}-base.png",
            overlayImage: "/static-img/mockup/HARD/HARD-{DEVICE_CODE}-overlay.png",
        },
        GENERAL2: {
            areaName: "2",
            phoneBaseImage: "/static-img/mockup/HARD/HARD-{DEVICE_CODE}-base.png",
            overlayImage: "/static-img/mockup/HARD/HARD-{DEVICE_CODE}-overlay.png",
        },
    },
    ZHARD: {
        TOP: {
            areaName: "상판",
            phoneBaseImage: "/static-img/mockup/ZHARD/ZHARD-{DEVICE_CODE}-top-base.png",
            overlayImage: "/static-img/mockup/ZHARD/ZHARD-{DEVICE_CODE}-top-overlay.png",
        },
        BOTTOM: {
            areaName: "하판",
            phoneBaseImage: "/static-img/mockup/ZHARD/ZHARD-{DEVICE_CODE}-bottom-base.png",
            overlayImage: "/static-img/mockup/ZHARD/ZHARD-{DEVICE_CODE}-bottom-overlay.png",
        },
    },
};

Object.freeze(PRODUCT_SETTINGS);