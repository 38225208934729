import React, { useState, useRef } from "react";
import "../style/ImageManagerDialog.css";
import {
  ERROR_MESSAGES,
  FILE_LIMITS,
} from "../constants/Constants";

const ImageManagerDialog = ({ addImage, marketId, userId }) => {

    console.log(`********************************************* ImageManagerDialog 렌더링`);

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;

    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const rembgFileInputRef = useRef(null);
    const modalRef = useRef(null);

    // 모달 외부 클릭 시 닫기
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    // 이미지 목록 조회
    const fetchImages = async () => {
        console.log("SERVER_DOMAIN=", SERVER_DOMAIN);
        try {
            setLoading(true);
            const response = await fetch(
                SERVER_DOMAIN +
                    "/api/user-image/list?marketId=" + marketId + "&userId=" + userId
            );
            const data = await response.json();
            console.log("data=", data);
            setImages(data.resObj.result);
        } catch (error) {
            console.error("Failed to fetch images:", error);
        } finally {
            setLoading(false);
        }
    };

    // 이미지 삭제
    const handleDeleteImage = async (marketId, userId, no) => {
        // if (!confirm('정말 삭제하시겠습니까?')) {
        //   return;
        // }
 
        try {
            await fetch(
                SERVER_DOMAIN +
                    `/api/user-image/delete/${marketId}/${userId}/${no}`,
                {
                    method: "DELETE",
                }
            );
            await fetchImages(); // 목록 갱신
        } catch (error) {
            console.error("Failed to delete image:", error);
        }
    };

    // 사용자 이미지 파일 체크
    const checkUserImageFile = async (file) => {
      return new Promise((resolve, reject) => {


        let isInvalidSize = false;
        let isInvalidMaxPixel = false;
        let isInvalidMinPixel = false;

        // 파일 크기 체크 (20MB)
        if (file.size > FILE_LIMITS.USER_IMAGE_LIMIT_SIZE) {
            isInvalidSize = true;
        }

        const reader = new FileReader();
        reader.onload = function (f) {
            const imgChk = new Image();
            imgChk.onload = function () {

              // 이미지 해상도 체크
                if (imgChk.width > 5000 || imgChk.height > 5000) {
                    isInvalidMaxPixel = true;
                } else if (imgChk.width < 100 || imgChk.height < 100) {
                    isInvalidMinPixel = true;
                }

                if (isInvalidSize && isInvalidMaxPixel) {
                    resolve(ERROR_MESSAGES.FILE_SIZE_OVER_AND_IMAGE_MAX_DIMENSION);
                    return;
                } else if (isInvalidSize) {
                    resolve(ERROR_MESSAGES.FILE_SIZE_OVER);
                    return;
                } else if (isInvalidMaxPixel) {
                    resolve(ERROR_MESSAGES.IMAGE_MAX_DIMENSION);
                    return;
                } else if (isInvalidMinPixel) {
                    resolve(ERROR_MESSAGES.IMAGE_MIN_DIMENSION);
                    return;
                } else {
                    resolve();
                }
            };
            imgChk.src = f.target.result;
        };
        reader.readAsDataURL(file);
      });
    };

    // 이미지 업로드
    const handleUpload = async (event, isRemBg = false) => {
        const requestUrl = !isRemBg ? "/api/user-image/upload" : "/api/user-image/upload-rembg"


        const file = event.target.files[0];
        if (!file) return;

        const chkMsg = await checkUserImageFile(file);
        if (chkMsg) {
          alert(chkMsg);
          return;
        }
        // console.log(`---------chkMsg`,chkMsg);
    
        const formData = new FormData();
        formData.append("marketId", marketId);
        formData.append("userId", userId);
        formData.append("file", file);

        try {
            setLoading(true);
            const response = await fetch(SERVER_DOMAIN + requestUrl, {
                method: "POST",
                body: formData,
            });
            const data = await response.json();
            if (data.resCd === "SUCCESS") {
                await fetchImages(); // 목록 갱신
            } else {
                alert(data.resMsg);
            }
        } catch (error) {
            console.error("Failed to upload image:", error);
        } finally {
            setLoading(false);
            event.target.value = ""; // 파일 입력 초기화
        }
    };

    // 이미지 선택
    const handleImageSelect = (imgUrl) => {
        addImage(IMG_SERVER_DOMAIN + imgUrl);
        setOpen(false);
    };


 
    return (
        <>
            <button className="tmp-btn"
                onClick={() => {
                    setOpen(true);
                    fetchImages();
                }}
            >
                이미지 관리
            </button>

            {open && (
                <div className="modal-overlay" onClick={handleClickOutside}>
                    <div className="modal-content" ref={modalRef}>
                        <div className="modal-header">
                            <h2>나의 이미지</h2>
                            <button className="close-button" onClick={() => setOpen(false)}>
                                ✕
                            </button>
                        </div>

                        <div className="modal-body">
                            {loading ? (
                                <div className="loading-container">로딩중...</div>
                            ) : images.length === 0 ? (
                                <div className="empty-container">이미지를 업로드하세요.</div>
                            ) : (
                                <div className="image-grid">
                                    {images.map((image) => (
                                        <div key={image.imgNo} className="image-item">
                                            <img
                                                src={IMG_SERVER_DOMAIN + image.imgUrl}
                                                alt={image.stickerName}
                                                onClick={() => handleImageSelect(image.imgUrl)}
                                            />
                                            <button
                                                onClick={() => handleDeleteImage(marketId, userId, image.no)}
                                                className="delete-button"
                                            >
                                                ✕
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="modal-footer">
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={(e) => handleUpload(e, false)}
                                accept=".jpg, .jpeg, .png, .webp"
                                className="hidden"
                            />
                            <button
                                onClick={() => fileInputRef.current?.click()}
                                disabled={loading}
                                className="upload-button"
                            >
                                이미지 업로드
                            </button>
                            <input
                                type="file"
                                ref={rembgFileInputRef}
                                onChange={(e) => handleUpload(e, true)}
                                accept=".jpg, .jpeg, .png, .webp"
                                className="hidden"
                            />
                            <button
                                onClick={() => rembgFileInputRef.current?.click()}
                                disabled={loading}
                                className="upload-button"
                            >
                                이미지 배경제거 후 업로드
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageManagerDialog;
