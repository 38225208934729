import React, { useState, useRef, useEffect } from "react";
import "../style/StickerDialog.css";

const StickerDialog = ({ isOpen, onClose, addImage }) => {

    const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const STICKER_IMG_URI = IMG_SERVER_DOMAIN + "/static-img/sticker/";

    const [categories, setCategories] = useState([]);
    const [stickers, setStickers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // 로드된 스티커 목록을 저장. 재사용.
    const stickersRef = useRef({});

    // 다이얼로그가 열리고 닫힐 때 상태 초기화
    useEffect(() => {
        if (!isOpen) {
            setSelectedCategory(null);
            setStickers([]);
        }
        else if (isOpen) {
            fetchCategories();
        }
        
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [isOpen]);

    // 카테고리가 선택되었을 때 스티커 목록 조회
    useEffect(() => {
        if (selectedCategory) {
            // 캐시된 데이터가 있는지 확인
            if (stickersRef.current && stickersRef.current[selectedCategory]) {
                setStickers(stickersRef.current[selectedCategory]);
            } else {
                fetchStickers(selectedCategory);
            }
        }
        return () => {
            setStickers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [selectedCategory]);


    const fetchCategories = async () => {
        if (categories && categories.length) {
            return;
        }

        try {
            const response = await fetch(SERVER_DOMAIN + "/api/sticker/category-list");
            const data = await response.json();
            if (data.resCd === "SUCCESS") {
                setCategories(data.resObj.stickerCategories);
            }
        } catch (error) {
            console.error("카테고리 목록 조회 실패:", error);
        }
    };

    const fetchStickers = async (categoryCode) => {

        setIsLoading(true);
        try {
            const response = await fetch(SERVER_DOMAIN + "/api/sticker/list?categoryCode=" + categoryCode);
            const data = await response.json();
            if (data.resCd === "SUCCESS") {
                setStickers(data.resObj.stickers);
                stickersRef.current[categoryCode] = data.resObj.stickers;
            }
        } catch (error) {
            console.error("스티커 목록 조회 실패:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCategoryClick = (categoryCode) => {
        setSelectedCategory(categoryCode);
    };

    const handleBack = () => {
        setSelectedCategory(null);
        setStickers([]);
    };

    if (!isOpen) return null;

    return (
        <div className="popup-container">
            <div className="popup-header">
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {selectedCategory && (
                        <button
                            className="back-button"
                            onClick={handleBack}
                            style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px",
                                color: "#666",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M19 12H5M12 19l-7-7 7-7" />
                            </svg>
                        </button>
                    )}
                    <h2 className="popup-title">{selectedCategory ? "스티커 선택" : "스티커 카테고리 선택"}</h2>
                </div>
                <button className="close-button" onClick={onClose}>
                    ✕
                </button>
            </div>

            <div className="content-container">
                {!selectedCategory ? (
                    // 카테고리 목록
                    <div className="sticker-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
                        {categories.map((category) => (
                            <div
                                key={category.no}
                                className="sticker-item"
                                onClick={() => handleCategoryClick(category.categoryCode)}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <h3 style={{ margin: 0, fontSize: "16px", fontWeight: "500" }}>
                                    {category.categoryName}
                                </h3>
                            </div>
                        ))}
                    </div>
                ) : (
                    // 스티커 목록
                    <div className="sticker-grid">
                        {isLoading ? (
                            <div style={{ gridColumn: "1 / -1", textAlign: "center", padding: "20px" }}>로딩 중...</div>
                        ) : (
                            stickers.map((sticker) => (
                                <div
                                    key={sticker.no}
                                    className="sticker-item"
                                    onClick={() => {
                                        addImage(STICKER_IMG_URI + sticker.stickerImageFilename);
                                        onClose();
                                    }}
                                >
                                    <img
                                        src={STICKER_IMG_URI + sticker.stickerImageFilename}
                                        alt={sticker.stickerName}
                                        className="sticker-image"
                                    />
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StickerDialog;
