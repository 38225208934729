import { fabric } from 'fabric';

/**
 * fabric.Image.fromURL을 Promise로 래핑하는 유틸리티 함수
 * @param {string} url - 이미지 URL
 * @param {Object} [options] - fabric.Image 옵션
 * @returns {Promise<fabric.Image>} fabric Image 객체
 */
export const loadImage = (url, options = { crossOrigin: 'Anonymous' }) => {
    return new Promise((resolve, reject) => {
        try {
            fabric.Image.fromURL(
                url, 
                (img) => resolve(img), 
                options
            );
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * fabric 객체의 clone 메서드를 Promise로 래핑
 * @param {fabric.Object} obj - 복제할 fabric 객체
 * @returns {Promise<fabric.Object>} 복제된 fabric 객체
 */
export const cloneObject = (obj) => {
    return new Promise((resolve, reject) => {
        try {
            obj.clone((cloned) => resolve(cloned));
        } catch (error) {
            reject(error);
        }
    });
};
