import React, { useState, useEffect } from 'react';
import { COLOR_PALETTE } from '../constants/Constants';

const ColorControls = ({ selectedObject, canvas, historyControlsRef }) => {

    console.log(`********************************************* ColorControls 렌더링`);

    const [objectColor, setObjectColor] = useState('');
    const [selectedColor, setSelectedColor] = useState('');

    // 팔레트 색상이 변경될 때 객체 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(1) - 객체 색상 업데이트`);

        if (!canvas || !objectColor) return;

        const activeObject = canvas.getActiveObject();

        if (activeObject) {
            if (activeObject.type === 'text' || activeObject.type === 'textbox' || activeObject.type === 'rect') {
                activeObject.set({
                    fill: objectColor
                });
                canvas.renderAll();
                setSelectedColor(objectColor);
                historyControlsRef.current.handleCanvasChange(); // 히스토리에 추가
            } else {
                setSelectedColor(null);
            }
        } else {
            setSelectedColor(null);
        }
               
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [objectColor]);

    // 선택된 객체가 변경될 때 팔레트 색상 업데이트
    useEffect(() => {
        console.log(`ColorControls::useEffect(2) - 팔레트 색상 업데이트`);
        if (selectedObject && (selectedObject.type === 'text' || selectedObject.type === 'textbox' || selectedObject.type === 'rect')) {
            setSelectedColor(selectedObject.fill || '#000000');
        } else {
            setSelectedColor('');
            setObjectColor('');
        }
    }, [selectedObject]);


    // 텍스트박스나 사각형인 경우에만 컨트롤 표시
    if (!selectedObject || (selectedObject.type !== 'text' && selectedObject.type !== 'textbox' && selectedObject.type !== 'rect') || selectedObject.lockMovement) {
        return null;
    }

    return (
        <div>
            <div>
                <span>표준 색상:</span>
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                    {COLOR_PALETTE.map((color) => (
                        <div
                            key={color}
                            onClick={() => setObjectColor(color)}
                            style={{
                                display: 'inline-block',
                                backgroundColor: color,
                                width: '20px',
                                height: '20px',
                                marginRight: '5px',
                                border: selectedColor === color ? '2px solid #000' : '1px solid #ccc',
                                cursor: 'pointer',
                            }}
                        ></div>
                    ))}
                </div>
            </div>

            <div>
                <span className="text-sm font-medium">상세 색상: </span>
                <input
                    type="color"
                    value={selectedColor}
                    onChange={(e) => setObjectColor(e.target.value)}
                    className="ml-2 cursor-pointer"
                />
            </div>
        </div>
    );
};

export default ColorControls;