import { fabric } from 'fabric';
import { loadImage, cloneObject } from '../utils/fabricUtils';
import { CANVAS_DIMENSIONS } from '../constants/Constants';

export const initMockupNever = async (fabricRef, checkAreaInsideRef, checkAreaOutsideRef, phoneBaseImageRef, historyControlsRef, deviceCode, initSettings) => {
    console.log('목업 초기화');
    
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const canvas = fabricRef.current;

    try {
        // 히스토리 기록 Off
        historyControlsRef.current.setIsRedoUndo(true);

        // 1. 바깥 영역 생성
        const outsideArea = new fabric.Rect({
            isStatic: true,
            name: 'outsideArea',
            width: canvas.width,
            height: canvas.height,
            left: 0,
            top: 0,
            fill: '#f6f6f6',
            selectable: false,
            evented: false,
            absolutePositioned: true,
        });

        // 2. 베이스 이미지 로드
        const phoneBaseImage = await loadImage(
            IMG_SERVER_DOMAIN + initSettings.phoneBaseImage.replace('{DEVICE_CODE}', deviceCode)
        );
        phoneBaseImage.set({
            isStatic: true,
            name: 'Phone Base Image',
            left: CANVAS_DIMENSIONS.CENTER_X - phoneBaseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - phoneBaseImage.height / 2,
            selectable: false,
            evented: false
        });
        canvas.add(phoneBaseImage);
        phoneBaseImageRef.current = phoneBaseImage;

        // 3. 체크 영역 생성
        const checkArea = new fabric.Rect({
            isStatic: true,
            width: phoneBaseImage.width,
            height: phoneBaseImage.height,
            left: CANVAS_DIMENSIONS.CENTER_X - phoneBaseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - phoneBaseImage.height / 2,
            fill: 'transparent',
            selectable: false,
            evented: false,
        });
        canvas.add(checkArea);
        checkAreaInsideRef.current = checkArea;
        checkAreaOutsideRef.current = checkArea;

        // 4. 클리핑 이미지 생성
        const clippingImage = await cloneObject(phoneBaseImage);
        clippingImage.set({
            isStatic: true,
            name: 'Clipping Image',
            left: CANVAS_DIMENSIONS.CENTER_X - phoneBaseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - phoneBaseImage.height / 2,
            selectable: false,
            evented: false,
            absolutePositioned: true,
            inverted: true
        });
        outsideArea.clipPath = clippingImage;

        // 5. 오버레이 이미지 로드
        const overlayImage = await loadImage(
            IMG_SERVER_DOMAIN + initSettings.overlayImage.replace('{DEVICE_CODE}', deviceCode)
        );
        overlayImage.set({
            isStatic: true,
            name: 'Overlay Image',
            left: CANVAS_DIMENSIONS.CENTER_X - overlayImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - overlayImage.height / 2,
            selectable: false,
            evented: false,
        });

        // 6. 그룹 생성 및 추가
        const group = new fabric.Group([outsideArea, overlayImage], {
            isStatic: true,
            name: 'Overlay Group',
            selectable: false,
            evented: false,
        });
        canvas.add(group);

        // 7. 배경색 설정
        const backgroundClippingImage = await cloneObject(phoneBaseImage);
        backgroundClippingImage.set({
            isStatic: true,
            name: 'BackgroundColor',
            left: CANVAS_DIMENSIONS.CENTER_X - phoneBaseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - phoneBaseImage.height / 2,
            selectable: false,
            evented: false,
            absolutePositioned: true,
        });

        // 8. 배경색 사각형 생성
        const bgColorRect = new fabric.Rect({
            isStatic: false,
            name: 'BackgroundColor',
            width: CANVAS_DIMENSIONS.ORIGINAL_WIDTH,
            height: CANVAS_DIMENSIONS.ORIGINAL_HEIGHT,
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: 'center',
            originY: 'center',
            fill: 'transparent',
            opacity: 1,
            selectable: false,
            evented: false,
            clipPath: backgroundClippingImage
        });
        bgColorRect.setControlsVisibility({
            mt: false, mb: false, ml: false, mr: false,
            bl: false, br: false, tl: false, tr: false, mtr: false
        });
        canvas.add(bgColorRect);

        // 9. 가이드라인 추가
        const vGuideLine = new fabric.Line(
            [
                CANVAS_DIMENSIONS.CENTER_X, 0,
                CANVAS_DIMENSIONS.CENTER_X, CANVAS_DIMENSIONS.ORIGINAL_HEIGHT
            ],
            {
                isStatic: true,
                name: 'vGuideLine',
                stroke: '#da2028',
                strokeWidth: 4,
                selectable: false,
                evented: false,
                strokeDashArray: [10, 10],
                opacity: 0,
            }
        );
        canvas.add(vGuideLine);

        // 히스토리 기록 On
        historyControlsRef.current.setIsRedoUndo(false);
        historyControlsRef.current.handleCanvasChange();

        console.log('목업 초기화 ==> 완료');
    } catch (error) {
        console.error('목업 초기화 중 오류 발생:', error);
        historyControlsRef.current.setIsRedoUndo(false);
    }
};