import React, { useEffect, useRef } from 'react';
import '../style/LayerPanel.css';

const LayerPanel = ({ canvas, hisIndex, selectedObject, setSelectedObject }) => {

    console.log(`********************************************* LayerPanel 렌더링`);

    const canvasObjectsRef = useRef([]);

    useEffect(() => {

        if (!canvas) {
            return;
        }

        canvasObjectsRef.current = [...canvas.getObjects()].filter(obj => obj.selectable).reverse();
        // canvasObjectsRef.current = [...canvas.getObjects()].reverse();

        return () => {
            canvasObjectsRef.current = null;
        };
    }, [canvas, hisIndex, selectedObject]);


    const getLayerName = (obj, index) => {

        const layerNumber = canvasObjectsRef.current.length - index;
        let layerName = `Layer ${layerNumber}: `;

        switch (obj.type) {
            case 'group':
                layerName += `그룹 (${obj.name})`;
                break;
            case 'image':
                layerName += `이미지 (${obj.name})`;
                break;
            case 'rect':
                layerName += `사각형 (${obj.fill})`;
                break;
            case 'textbox':
                layerName += `텍스트박스 (${obj.text})`;
                break;
            case 'text':
                layerName += `텍스트 (${obj.text})`;
                break;
            default:
                layerName += obj.type;
        }

        return layerName;
    };


    const selectObject = (obj) => {
        if (!canvas) return;

        canvas.discardActiveObject();
        canvas.setActiveObject(obj);
        setSelectedObject(obj);
        canvas.requestRenderAll();
    };


    if (!canvas) return null;



    return (
        <div className="layer-panel-container">
            {canvasObjectsRef.current.map((obj, index) => (
                <div
                    key={index}
                    className={`layer-item ${canvas.getActiveObject() === obj ? 'selected' : ''}`}
                    onClick={() => selectObject(obj)}
                >
                    {getLayerName(obj, index)}
                </div>
            ))}
        </div>
    );
};

export default LayerPanel;