import { fabric } from 'fabric';
import { loadImage, cloneObject } from '../utils/fabricUtils';
import { CANVAS_DIMENSIONS } from '../constants/Constants';

export const initMockup = async (fabricRef, checkAreaInsideRef, checkAreaOutsideRef, phoneBaseImageRef, historyControlsRef, deviceCode, initSettings) => {
    console.log('목업 초기화');
    
    const IMG_SERVER_DOMAIN = process.env.REACT_APP_IMG_SERVER_DOMAIN;
    const canvas = fabricRef.current;
    const PADDING = 200;

    try {
        // 히스토리 기록 Off
        historyControlsRef.current.setIsRedoUndo(true);

        // 1. 베이스 이미지 로드
        const baseImage = await loadImage(
            IMG_SERVER_DOMAIN + initSettings.phoneBaseImage.replace('{DEVICE_CODE}', deviceCode)
        );
        baseImage.set({
            isStatic: true,
            name: 'Phone Base Image',
            left: CANVAS_DIMENSIONS.CENTER_X - baseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - baseImage.height / 2,
            selectable: false,
            evented: false
        });
        canvas.add(baseImage);
        phoneBaseImageRef.current = baseImage;

        // 2. 내부 체크 영역 생성
        const checkArea = new fabric.Rect({
            isStatic: true,
            width: baseImage.width,
            height: baseImage.height,
            left: CANVAS_DIMENSIONS.CENTER_X - baseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - baseImage.height / 2,
            fill: 'transparent',
            selectable: false,
            evented: false,
        });
        canvas.add(checkArea);
        checkAreaInsideRef.current = checkArea;

        // 3. 외부 체크 영역 생성
        const checkArea2 = await cloneObject(checkArea);
        checkArea2.set({
            isStatic: true,
            width: checkArea.width + PADDING,
            height: checkArea.height + PADDING,
            left: CANVAS_DIMENSIONS.CENTER_X - (checkArea.width + PADDING) / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - (checkArea.height + PADDING) / 2,
            fill: 'transparent',
            selectable: false,
            evented: false,
        });
        canvas.add(checkArea2);
        checkAreaOutsideRef.current = checkArea2;

        // 4. 점선 테두리 생성
        const border = new fabric.Rect({
            isStatic: true,
            name: 'border',
            width: baseImage.width + PADDING,
            height: baseImage.height + PADDING,
            left: CANVAS_DIMENSIONS.CENTER_X - (baseImage.width + PADDING) / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - (baseImage.height + PADDING) / 2,
            fill: 'transparent',
            stroke: '#888888',
            strokeWidth: 4,
            strokeDashArray: [5, 5],
            rx: 20,
            ry: 20,
            selectable: false,
            evented: false,
        });

        // 5. 외부 영역 생성
        const outsideArea = new fabric.Rect({
            isStatic: true,
            name: 'outsideArea',
            width: canvas.width,
            height: canvas.height,
            left: 0,
            top: 0,
            fill: '#f6f6f6',
            selectable: false,
            evented: false,
            clipPath: new fabric.Rect({
                width: baseImage.width + PADDING,
                height: baseImage.height + PADDING,
                left: - baseImage.width / 2 - (PADDING) / 2,
                top: - baseImage.height / 2 - (PADDING) / 2,
                inverted: true,
                rx: 20,
                ry: 20,
            })
        });

        // 6. 내부 영역 생성
        const insideArea = new fabric.Rect({
            isStatic: true,
            name: 'insideArea',
            width: baseImage.width + PADDING,
            height: baseImage.height + PADDING,
            left: CANVAS_DIMENSIONS.CENTER_X - (baseImage.width + PADDING) / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - (baseImage.height + PADDING) / 2,
            fill: '#efefef',
            opacity: 0.7,
            rx: 20,
            ry: 20,
            selectable: false,
            evented: false,
        });

        // 7. 클리핑 이미지 생성
        const clippedImage = await cloneObject(baseImage);
        clippedImage.set({
            isStatic: true,
            name: 'Clipping Image',
            left: CANVAS_DIMENSIONS.CENTER_X - baseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - baseImage.height / 2,
            selectable: false,
            evented: false,
            absolutePositioned: true,
            inverted: true
        });
        insideArea.clipPath = clippedImage;

        // 8. 오버레이 이미지 로드
        const overlayImage = await loadImage(
            IMG_SERVER_DOMAIN + initSettings.overlayImage.replace('{DEVICE_CODE}', deviceCode)
        );
        overlayImage.set({
            isStatic: true,
            name: 'Overlay Image',
            left: CANVAS_DIMENSIONS.CENTER_X - overlayImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - overlayImage.height / 2,
            selectable: false,
            evented: false,
        });

        // 9. 그룹 생성
        const group = new fabric.Group([insideArea, outsideArea, border, overlayImage], {
            isStatic: true,
            name: 'Overlay Group',
            selectable: false,
            evented: false,
        });
        canvas.add(group);

        // 10. 배경색 설정
        const backgroundClippingImage = await cloneObject(baseImage);
        backgroundClippingImage.set({
            isStatic: true,
            name: 'BackgroundColor',
            left: CANVAS_DIMENSIONS.CENTER_X - baseImage.width / 2,
            top: CANVAS_DIMENSIONS.CENTER_Y - baseImage.height / 2,
            selectable: false,
            evented: false,
            absolutePositioned: true,
        });

        const bgColorRect = new fabric.Rect({
            isStatic: true,
            name: 'BackgroundColor',
            width: CANVAS_DIMENSIONS.ORIGINAL_WIDTH,
            height: CANVAS_DIMENSIONS.ORIGINAL_HEIGHT,
            left: CANVAS_DIMENSIONS.CENTER_X,
            top: CANVAS_DIMENSIONS.CENTER_Y,
            originX: 'center',
            originY: 'center',
            fill: '#ffffff',
            opacity: 1,
            selectable: false,
            evented: false,
            clipPath: backgroundClippingImage
        });
        bgColorRect.setControlsVisibility({
            mt: false, mb: false, ml: false, mr: false,
            bl: false, br: false, tl: false, tr: false, mtr: false
        });
        canvas.add(bgColorRect);

        // 11. 가이드라인 추가
        const vGuideLine = new fabric.Line(
            [
                CANVAS_DIMENSIONS.CENTER_X, 0,
                CANVAS_DIMENSIONS.CENTER_X, CANVAS_DIMENSIONS.ORIGINAL_HEIGHT
            ],
            {
                isStatic: true,
                name: 'vGuideLine',
                stroke: '#da2028',
                strokeWidth: 1,
                selectable: false,
                evented: false,
                strokeDashArray: [5, 5],
                opacity: 0,
            }
        );
        canvas.add(vGuideLine);

        canvas.renderAll();

        // 히스토리 기록 On
        historyControlsRef.current.setIsRedoUndo(false);
        historyControlsRef.current.handleCanvasChange();

        console.log('목업 초기화 ==> 완료');
    } catch (error) {
        console.error('목업 초기화 중 오류 발생:', error);
        historyControlsRef.current.setIsRedoUndo(false);
    }
};