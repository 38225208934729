export const MARKET_ID = {
    "183.99.52.66": "CAFE24",
    "localhost": "CAFE24",
    "svc.akan.co.kr": "CAFE24",
    // "akan.kr": "WHOLESALE"
};

// 캔버스 크기 관련 상수
export const CANVAS_DIMENSIONS = {
    ORIGINAL_WIDTH: 1600,
    ORIGINAL_HEIGHT: 1600,
    get CENTER_X() {
        return this.ORIGINAL_WIDTH / 2;
    },
    get CENTER_Y() {
        return this.ORIGINAL_HEIGHT / 2;
    },
    PADDING: 200,
};

// 스냅 임계값 설정
export const SNAP_THRESHOLD = 10;

// Fabric.js 객체 속성 관련 상수
export const FABRIC_JSON_PROPERTIES = [
    "selectable",
    "evented",
    // 'lockMovementX',
    // 'lockMovementY',
    // 'lockRotation',
    // 'lockScalingX',
    // 'lockScalingY',
    // 'lockSkewingX',
    // 'lockSkewingY',
    // 'lockUniScaling',
    "hasControls",
    "crossOrigin",
    "opacity",
    "originX",
    "originY",
    "padding",
    "scaleX",
    "scaleY",
    "top",
    "left",
    "width",
    "height",
    "fill",
    "fillRule",
    "stroke",
    "strokeWidth",
    "strokeDashArray",
    "group",
    "name",
];

// 컨트롤 설정
export const CONTROL_VISIBILITY = {
    ml: false,
    mb: false,
    mr: false,
    mt: false,
};

// 텍스트 박스 컨트롤 설정
export const CONTROL_VISIBILITY_TEXTBOX = { mb: false, mt: false };

// 텍스트 관련 기본값
export const TEXT_DEFAULTS = {
    COLOR: "#000000",
    FONT_SIZE: 80,
    LINE_HEIGHT: 1.0,
    CHAR_SPACING: 0,
};

// 컬러 팔레트
export const COLOR_PALETTE = [
    "#ffffff",
    "#000000",
    "#a7a9ab",
    "#1f255e",
    "#8ec8e2",
    "#88479b",
    "#da2028",
    "#ecaccd",
    "#ffcb31",
    "#f47920",
    "#fdd0a7",
    "#f7e9b2",
    "#2f823f",
    "#ddeeda",
];

// 파일 업로드 관련 제한
export const FILE_LIMITS = {
    USER_IMAGE_LIMIT_SIZE: 20 * 1024 * 1024, // 20MB
    MAX_DIMENSION: 5000, // 5000px
    ALLOWED_TYPES: ["image/jpeg", "image/png", "image/gif"],
};

// 이벤트 디바운스/쓰로틀 타이밍
export const TIMING = {
    DEBOUNCE_DELAY: 250,
    THROTTLE_DELAY: 100,
};

// API 엔드포인트

export const API_INFO = {
    ENDPOINTS: {
        USER_AUTH_VERIFY: "/api/auth/verify",
        USER_IMAGE_UPLOAD: "/api/user-image/upload",
        EDITED_IMAGE_UPLOAD: "/api/edited-image/upload",
        EDITED_IMAGE_UPLOAD_DATA: "/api/edited-image/upload-data",
        EDITED_IMAGE_LOAD: "/api/edited-image/load/",
    },
    JSON_HEADERS: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
    MULTIPART_HEADERS: {
        "Accept": "application/json",
    }
}

export const API_ENDPOINTS = {
    USER_AUTH_VERIFY: "/api/auth/verify",
    SAVE_CANVAS: "/api/canvas/save",
    LOAD_CANVAS: "/api/canvas/load",
    UPLOAD_IMAGE: "/api/upload",
};

// API HEADER
export const API_JSON_HEADERS = {
    "Content-Type": "application/json",
    "Accept": "application/json",
};

export const API_MULTIPART_HEADERS = {
    "Accept": "application/json",
};

// 에러 메시지
export const ERROR_MESSAGES = {
    FILE_SIZE_OVER_AND_IMAGE_MAX_DIMENSION:
        "파일 크기는 20MB를 초과할 수 없고 해상도는 5000px이하여야 합니다.",
    FILE_SIZE_OVER: "파일 크기는 20MB를 초과할 수 없습니다.",
    IMAGE_MAX_DIMENSION: "5000px 이상의 이미지는 업로드가 불가능합니다.",
    IMAGE_MIN_DIMENSION:
        "500px 미만의 이미지는 품질저하로 업로드가 불가능합니다.",
    INVALID_FILE_TYPE: "지원하지 않는 파일 형식입니다.",
    LOAD_FAILED: "캔버스 로드에 실패했습니다.",
    SAVE_FAILED: "캔버스 저장에 실패했습니다.",
    FONT_LOAD_FAILED: "폰트 로딩에 실패했습니다.",
};

// 상수 객체를 불변으로 만들기
Object.freeze(CANVAS_DIMENSIONS);
Object.freeze(CONTROL_VISIBILITY);
Object.freeze(TEXT_DEFAULTS);
Object.freeze(COLOR_PALETTE);
Object.freeze(FILE_LIMITS);
Object.freeze(TIMING);
Object.freeze(API_INFO);
Object.freeze(API_ENDPOINTS);
Object.freeze(API_JSON_HEADERS);
Object.freeze(ERROR_MESSAGES);
